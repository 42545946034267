// base-page.component.ts
import { Directive, OnInit, OnDestroy } from '@angular/core';
import { Logger } from '@gci/helpers/logger';
import { TranslocoService } from '@jsverse/transloco';
import { Subject, takeUntil } from 'rxjs';

@Directive()  
export abstract class BaseComponent implements OnInit, OnDestroy {
  private readonly _logr = new Logger('BaseComponent');
  protected activeLanguage : string = '';
  protected unsubscribeAll: Subject<any> = new Subject<any>();
  protected logger!: Logger;

  constructor(
    protected componentName: string,
    protected translateService?: TranslocoService,
  ) {
    this.logger = new Logger(componentName);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void { 
    if (this.translateService) {
      this.translateService.langChanges$
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((activeLang)=> {
        this._logr.debug(`${this.componentName} language changes detected`, activeLang);
        this.activeLanguage = activeLang;
        this.onLanguageChanged(activeLang);
      });
    }

    this._logr.debug(`${this.componentName} ngOnInit`);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {    
    // Unsubscribe from all subscriptions
    this.unsubscribeAll.next(null);
    this.unsubscribeAll.complete();
  }

  /**
   * On language change will be called when language changes
   * @param lang  new language code
   */
  onLanguageChanged(lang: string): void {
  }

}
